import axios from "../axios.config";

export function getAllEvaluations(
  accessToken: string,
  year?: any,
  search?: string,
  offset?: any,
  limit?: any,
  order?: any,
  type?: any
) {
  return axios.get(
    `/rpeople/evaluations?c=${year ? `&year=${year}` : ""}${
      offset ? `&offset=${offset}` : ""
    }${limit ? `&limit=${limit}` : ""}${
      order ? `&order=${order[0]}&order=${order[1]}` : ""
    }${search ? `&search=${search}` : ""}${type ? `&type=${type}` : ""}`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getExtraordinaryEvaluations(
  accessToken: string,
  year?: any,
  search?: string,
  offset?: any,
  limit?: any,
  order?: any,
  type?: any
) {
  return axios.get(
    `/rpeople/evaluations/extraordinary?c=${year ? `&year=${year}` : ""}${
      offset ? `&offset=${offset}` : ""
    }${limit ? `&limit=${limit}` : ""}${
      order ? `&order=${order[0]}&order=${order[1]}` : ""
    }${search ? `&search=${search}` : ""}${type ? `&type=${type}` : ""}`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function postEvaluation(accessToken: string, evaluation: any) {
  return axios.post("/rpeople/evaluations", evaluation, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function createGroup(accessToken: string, group: any) {
  return axios.post("/competency-groups", group, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getEmployeesEvaluators(accessToken: string, ids: any) {
  return axios.get(`/rpeople/evaluators?id=${ids}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getEvaluationForm(accessToken: string, id_employee?: any) {
  return axios.get(
    `/rpeople/form${id_employee ? `?employee=${id_employee}` : ""}`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getEvaluationSkills(
  accessToken: string,
  id_employee: any,
  id_evaluation: any
) {
  return axios.get(`/rpeople/skills/form`, {
    params: {
      employee: id_employee,
      evaluation: id_evaluation,
    },
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getEvaluationById(accessToken: string, evaluationId: any) {
  return axios.get(`/rpeople/evaluations/${evaluationId}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getEvaluatorResponses(
  accessToken: string,
  evaluationId: any,
  evaluator?: any
) {
  return axios.get(
    `/rpeople/responses?id_avaliacao=${evaluationId}${
      evaluator ? `&id_evaluator=${evaluator}` : ""
    }`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getSectorCompetency(accessToken: string, sector: any) {
  return axios.get(`/competency-groups/sector-expected?sector=${sector}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getExpectedLevelsBySector(accessToken: string, sector: any) {
  return axios.get(
    `/competency-groups/levels/sector-expected?sector=${sector}`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getSectorHierarchyCompetency(accessToken: string) {
  return axios.get("/competency-groups/sector-base", {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function submitEvaluatorResponses(accessToken: string, body: any) {
  return axios.post("/rpeople/responses", body, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getAllEvaluatorResponses(accessToken: string) {
  return axios.get(`/rpeople/responses/all`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getCompetencyLevels(accessToken: string, sector?: any) {
  return axios.get(`/competency-groups/expectedLevels?sector=${sector}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getCompetencyHierarchyLevels(accessToken: string) {
  return axios.get("/competency-groups/levels-base", {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updateSelfEvaluation(accessToken: string, collaborators: any) {
  return axios.put("/rh_ad/sector-collaborators", collaborators, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updateCompetencyLevel(
  accessToken: string,
  competency: any,
  sector: any
) {
  return axios.put(`/rh_ad/competency-level?setor=${sector}`, competency, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getUserEvaluationsConfig(accessToken: string) {
  return axios.get("/rpeople/configs", {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updateExpectedLevels(
  accessToken: string,
  competency: any,
  competencyId: any,
  sector?: any
) {
  return axios.put(
    `/rh_ad/expectedLevels?id=${competencyId}${
      sector ? `&sector=${sector}` : ""
    }`,
    competency,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function updateNiveisEsperadosSector(
  accessToken: string,
  levels: any,
  sector?: any
) {
  return axios.put(`/rh_ad/indicator/expectedLevels`, levels, {
    params: {
      setor: sector || null,
    },
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function putUserConfigs(accessToken: string, body: any) {
  return axios.put("/rpeople/configs", body, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updateExpectedGroups(
  accessToken: string,
  competency: any,
  competencyId: any
) {
  return axios.put(
    `/competency-groups/expectedLevels?id=${competencyId}`,
    competency,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function updateCompetencyWihtIndicators(
  accessToken: string,
  body: any,
  create: any,
  competencyId?: any
) {
  return axios.put(`/competency-groups/update-competency`, body, {
    params: {
      create,
      competencia: competencyId || null,
    },
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function deleteIndicator(accessToken: string, indicator: any) {
  return axios.patch(
    `/competency-groups/delete-indicator/${indicator}`,
    {},
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getHelpScales(accessToken: string, competencyId: any) {
  return axios.get(`/competency-groups/helpScales?id=${competencyId}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updateCompetencyHelpScale(
  accessToken: string,
  competency: any,
  competencyId: any
) {
  return axios.put(
    `/competency-groups/helpScales?id=${competencyId}`,
    competency,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function deleteCompetency(accessToken: string, competencyId: any) {
  return axios.patch(
    `/competency-groups/competency?id=${competencyId}`,
    {},
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function updateCompetencyGroup(
  accessToken: string,
  group: any,
  groupId: any
) {
  return axios.put(`/competency-groups?id=${groupId}`, group, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function deleteCompetencyGroup(accessToken: string, competencyId: any) {
  return axios.patch(
    `/competency-groups?id=${competencyId}`,
    {},
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getCompetecyFWithPonderation(accessToken: string) {
  return axios.get("/competency-groups/feedback", {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function createCompetencyGroupF(accessToken: string, group: any) {
  return axios.post("/competency-groups/groupF", group, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updateCompentencyPercentage(
  accessToken: string,
  competencyId: any,
  percentual: any
) {
  return axios.put(`/competency-groups/groupF?id=${competencyId}`, percentual, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function createCompetency(
  accessToken: string,
  groupId: any,
  competency: any
) {
  return axios.post(`/competency-groups/competency?id=${groupId}`, competency, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getAllSectors(accessToken: string) {
  return axios.get("/rh_ad/sectors", {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getEvaluators(accessToken: string, sectorId: any) {
  return axios.get(`/rh_ad/evaluator-sectors?id=${sectorId}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updateEvaluatorsSector(
  accessToken: string,
  sectorId: any,
  evaluators: any
) {
  return axios.put(`/rh_ad/evaluator-sectors?id=${sectorId}`, evaluators, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updateSectorManagers(accessToken: string, sectors: any) {
  return axios.put("/rh_ad/sector-manager", sectors, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getAllPonderationEvaluationComponents(accessToken: string) {
  return axios.get("/rh_ad/ponderations", {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updatePonderationEvaluationComponents(
  accessToken: string,
  departmentId: any,
  ponderations: any
) {
  return axios.put(`/rh_ad/ponderations?id=${departmentId}`, ponderations, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getIndicatorPonderation(accessToken: string) {
  return axios.get("/rh_ad/ponderations-indicators", {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updateIndicatorPonderation(
  accessToken: string,
  ponderations: any
) {
  return axios.put("/rh_ad/ponderations-indicators", ponderations, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getSefEvaluationCodes(
  accessToken: string,
  data: any,
  haveCode?: any
) {
  return axios.get(
    `/rh_ad/evaluation-codes?data=${data}${
      haveCode ? `&code=${haveCode}` : ""
    } `,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export const submitSelfEvaluation = (accessToken: any, body: any) => {
  return axios.post("/self-evaluation", body, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
};

export function getSelfEvaluation(accessToken: string, id: any) {
  return axios.get(`/rpeople/evaluations/self-evaluation/${id}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getAllEmployeeEvaluations(accessToken: string, id: any) {
  return axios.get(`/rpeople/evaluations/employee/${id}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getReportData(accessToken: string, id: any, id2?: any) {
  return axios.get(
    `/rpeople/report?evaluations=${id2 && id != id2 ? `${id},${id2}` : id}`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getEvaluationPeriods(sector?: number) {
  return axios.get(
    `/rh_ad/evaluation-periods${sector ? "?sector=" + sector : ""}`
  );
}

export function updateAccessCode(
  accessToken: string,
  colaboradorId: any,
  avaliacaoId: any
) {
  return axios.put(
    `/rh_ad/evaluation-codes?id=${colaboradorId}&avaliacao=${avaliacaoId}`,
    {},
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getEvaluationHistory(
  accessToken: string,
  type: any,
  sectors?: [],
  date?: [string, string],
  year?: number
) {
  return axios.get(
    `/rh_ad/historic?type=${type}&sectors=${sectors}${
      date ? `&date=${date[0]}&date=${date[1]}` : ""
    }${year ? `&year=${year}` : ""}`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getPeriods(accessToken: string, type?: string, user?: any) {
  return axios.get(
    `/rh_ad/periods?p=${type ? `&type=${type}` : ""}${
      user ? `&user=${user}` : ""
    }`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getExtraordinaryPeriods(accessToken: string) {
  return axios.get("/rh_ad/extraordinary-periods", {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getSectorEvaluationByCompetency(
  accessToken: string,
  sectors: any,
  years: any,
  competency: any
) {
  return axios.get(
    `/rpeople/sector-competency-average?sectors=${sectors}&years=${years}&competency=${competency}`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getSectorEmployeesEvaluationByYear(
  accessToken: string,
  sectors: any,
  years: any
) {
  return axios.get(
    `/rpeople/sector-employees-evaluations?sectors=${sectors}${
      years ? `&years=${years}` : ""
    }`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function feedbackMethod(accessToken: string, body: any) {
  return axios.post(`/rh_ad/feedback-method`, body, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getFeedbackConfirmation(hash: any, nif?: any) {
  return axios.get(`/rh_ad/feedback?hash=${hash}${nif ? `&nif=${nif}` : ""}`);
}

export function createFeedbackConfirmation(hash: any, body: any) {
  return axios.post(`/rh_ad/feedback?hash=${hash}`, body);
}

export function getOtherEvaluations(accessToken: string, avaliacaoId: any) {
  return axios.get(`/rh_ad/other-evaluations?id=${avaliacaoId}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getSectorByPeriod(
  accessToken: string,
  type: any,
  period?: [string, string],
  year?: any
) {
  return axios.get(
    `/rh_ad/sectors-period?type=${type}${
      period ? `&period=${period[0]}&period=${period[1]}` : ""
    }${year ? `&year=${year}` : ""}`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getCostCenters(
  accessToken: string,
  type: any,
  period?: [string, string],
  year?: any
) {
  return axios.get(
    `/rh_ad/costcenters?type=${type}${
      period ? `&period=${period[0]}&period=${period[1]}` : ""
    }${year ? `&year=${year}` : ""}`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getAllEvaluators(accessToken: string, period: any) {
  let periods = period.split("/");
  return axios.get(
    `/rpeople/all-evaluators?period=${periods[0]}&period=${periods[1]}`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getAllEvaluatorsEvaluations(
  accessToken: string,
  period: any,
  evaluators: any
) {
  const periods = period.split("/");
  return axios.get(
    `/rpeople/evaluators/evaluations?period=${periods[0]}&period=${periods[1]}&evaluators=${evaluators}`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getAllSectorEvaluations(
  accessToken: any,
  sectors: any,
  period: any,
  selfEvaluation?: any
) {
  let periods = period.split("/");
  return axios.get(`/rpeople/sectors/evaluations`, {
    params: {
      sectors,
      periods,
      selfEvaluation,
    },
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getEmployeesEvaluationByCompetency(
  accessToken: any,
  competency: any,
  employees: any,
  cicle: any,
  avaliador?: any,
  sector?: any,
  evaluationId?: any
) {
  return axios.get(`/rpeople/employees/competencies/evaluations`, {
    params: {
      cicle: cicle.split("/"),
      employees,
      competency: competency || null,
      avaliador: avaliador ? "S" : null,
      sector: sector || null,
      avaliacao: evaluationId || null,
    },
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getPeriodSectorEvaluation(accessToken: string) {
  return axios.get("/rh_ad/periods/sectors", {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getEvaluatedSectors(
  accessToken: string,
  date: any,
  sectorsId?: any,
  id_colaborador?: any
) {
  return axios.get(
    `/rh_ad/historic/sectors?data=${date}&sectors=${sectorsId}&id_colaborador=${id_colaborador}`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getGlobalAverage(accessToken: string, year: any) {
  return axios.get(`/rpeople/reports/global-average?year=${year}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getTrainingProposalsReport(accessToken: string, date: any) {
  return axios.get(`/rpeople/reports/trainning-proposals?data=${date}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getExportsToCsv(accessToken: string, date: any) {
  return axios.get(`/rpeople/reports/exports-csv?data=${date}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getGlobalReport(accessToken: string, date: any) {
  return axios.get(`/rpeople/reports/global-report?data=${date}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getFeedbackConfirmationsByEvaluator(
  accessToken: string,
  date: any,
  dados?: any
) {
  return axios.get(
    `/rpeople/reports/feedback-confirmations?data=${date}&dados=${dados}`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getFeedbackConfirmationsBySector(
  accessToken: string,
  date: any,
  dados?: any
) {
  return axios.get(
    `/rpeople/reports/feedback-confirmations-sector?data=${date}&dados=${dados}`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getCompetencies(accessToken: string, active?: any) {
  return axios.get("/competency-groups/competencies", {
    params: {
      active: active || null,
    },
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getSectorFeedbackEvaluationPeriods(
  accessToken: string,
  sector1: number,
  sector2?: number
) {
  return axios.get(
    `/rpeople/reports/sectors/periods?sector=${sector1}&sector=${sector2}`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getSectorFeedbackEvaluation(
  accessToken: string,
  sector1: number,
  sector2: any,
  period1: any,
  period2: any,
  evaluator?: any
) {
  return axios.get(
    `/rpeople/reports/sectors?sector=${sector1}&sector=${sector2}&inicio_periodo=${period1}&fim_periodo=${period2}${
      evaluator ? `&evaluator=${evaluator}` : ""
    }`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function createTrainningNeed(
  accessToken: string,
  evaluationId: any,
  collaboratorId: any,
  body: any
) {
  return axios.post(
    `/rh_ad/need-formation?avaliacao=${evaluationId}&colaborador=${collaboratorId}`,
    body,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function haveTrainningNeeds(accessToken: string, evaluationId: number) {
  return axios.get(`/rh_ad/need-formation?id=${evaluationId}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getSectorFeedbackEvaluations(
  accessToken: string,
  sectorId: string,
  cicle: any
) {
  return axios.get(
    `/rpeople/reports/sector-evaluations?sector=${sectorId}&cicle=${cicle}`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function notifyAdministration(accessToken: string, body: any) {
  return axios.post("/rpeople/administration", body, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getEvaluatedEmployees(accessToken: string, search?: string) {
  return axios.get(`/rpeople/employees?search=${search}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function deleteCollaborator(accessToken: string, id: any) {
  return axios.patch(
    `rh_ad/collaborators/delete/${id}`,
    {},
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getEvaluatorsEvaluated(accessToken: string) {
  return axios.get("/rpeople/reports/evaluators-evaluated", {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getAllHierarchicalLevels(accessToken: string, active?: any) {
  return axios.get("/rh_ad/hierarchical-levels", {
    params: {
      active: active || null,
    },
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getScale(active?: any) {
  return axios.get("/rh_ad/scale", {
    params: {
      active: active || null,
    },
  });
}

export function getEvaluationComponents(
  accessToken: string,
  collaborator: any,
  evaluation: any
) {
  return axios.get("/rh_ad/historic/evaluation-components", {
    params: {
      avaliacao: evaluation || null,
      colaborador: collaborator || null,
    },
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}
