import { Breadcrumb, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import classes from "./News.module.scss";
import clsx from "clsx";
import { useMemo, useState } from "react";
import Button from "../../components/UI/Button/Button";

const Organograma: React.FC = () => {
  const navigate = useNavigate();

  const [selectedButton, setSelectedButton] = useState<string>("riopele");

  const buttons = useMemo(
    () => [
      { value: "riopele", label: "Riopele" },
      { value: "rfs", label: "RFS" },
    ],
    []
  );

  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={() => {
            navigate("/noticias");
          }}
        >
          Início
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Organograma</Breadcrumb.Item>
      </Breadcrumb>

      <div className={clsx(classes.introduction, "col-12 mb-4")}>
        <p className="mb-0">Organograma</p>
      </div>
      <div className="d-flex justify-content-center gap-3 mb-6">
        {buttons.map((button: any) => (
          <Button
            style={
              selectedButton == button.value
                ? "btn btn-primary"
                : "btn btn-secondary"
            }
            onClick={() => {
              setSelectedButton((prev: any) => {
                return button.value;
              });
            }}
            title={button.label}
            key={button.value}
          />
        ))}
      </div>

      <div className="separator mb-6"></div>

      {selectedButton == "riopele" ? (
        <img width="100%" src="/media/news/organogramas_v1710.jpg" />
      ) : null}
      {selectedButton == "rfs" ? (
        <div className="d-flex justify-content-center">
          <img width="30%" src="/media/news/organograma_rfs_v2.jpg" />
        </div>
      ) : null}
    </Container>
  );
};

export default Organograma;
