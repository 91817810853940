import React from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { MasterLayout } from "../components/_metronic/layout/MasterLayout";
import { useEffect } from "react";

import PrivateRoutes from "./Private";

//COMPONENTS
import AutoAvaliacaoLayout from "../pages/RPeople/AutoAvaliacao/AutoAvaliacaoLayout";
import NewsLayout from "../pages/News/NewsLayout";
import Feedback from "../pages/RPeople/Historico/FeedbackConfirmation";
import Loading from "../pages/Loading/Loading";
import Organograma from "../pages/News/Organograma";

//PAGES
//QUESTIONARIOS
const Questionario = React.lazy(
  () => import("../pages/DRH/Questionarios/Questionario")
);
const FormacoesQuestionario = React.lazy(
  () => import("../pages/DRH/Formacoes/Lista/TabsTurmas/Questionario")
);

//REGISTO PRESENCA
const RegistoPresenca = React.lazy(
  () => import("../pages/RegistoReunioes/Presenca")
);

//AUTOAVALIACAO
const AutoAvaliacao = React.lazy(
  () => import("../pages/RPeople/AutoAvaliacao/AutoAvaliacao")
);
const AutoAvaliacaoInicio = React.lazy(
  () => import("../pages/RPeople/AutoAvaliacao/AutoAvaliacaoInicio")
);
const QuestionarioAutoAvaliacao = React.lazy(
  () => import("../pages/RPeople/AutoAvaliacao/QuestionarioAutoAvaliacao")
);

const Auth = React.lazy(() => import("../pages/Login/Auth"));
const LandingPage = React.lazy(() => import("../pages/Login/LandingPage"));

const SharedContent = React.lazy(
  () => import("../pages/MediaKit/Visualize/SharedContent")
);

//NEWS
const News = React.lazy(() => import("../pages/News/News"));
const Rgpd = React.lazy(() => import("../pages/News/Rgpd"));
const Sugestoes = React.lazy(() => import("../pages/News/Sugestoes"));
const DetalheNoticia = React.lazy(() => import("../pages/News/DetalheNoticia"));
const DetalheEvento = React.lazy(() => import("../pages/News/DetalheEvento"));
const NotasInformativas = React.lazy(
  () => import("../pages/News/NotasInformativas")
);
const PhoneBook = React.lazy(() => import("../pages/News/PhoneBook"));
const Protocolos = React.lazy(() => import("../pages/News/Protocolos"));
const DetalheProtocolo = React.lazy(
  () => import("../pages/News/DetalheProtocolo")
);
const Horizontes = React.lazy(() => import("../pages/News/Horizontes"));
const Menu = React.lazy(() => import("../pages/News/Menu"));
const PontosEncontro = React.lazy(() => import("../pages/News/PontosEncontro"));

const DashboardsPublic = React.lazy(
  () => import("../pages/GestaoEnergia/DashboardPublic")
);

const ConfirmacaoRececao = React.lazy(
  () => import("../pages/Contabilidade/ConfirmacaoRececao")
);

const PublicRoutes: React.FC = () => {
  const user = useSelector((state: any) => state.user);
  const selfEvaluation = useSelector((state: any) => state.self_evaluation);

  const location = useLocation().pathname;

  const [searchParams] = useSearchParams();

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.startsWith("/noticias")) window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      {user.local !== null ? (
        <>
          {/* Always available */}
          {user.local ? (
            <>
              <Route path="/noticias" element={<NewsLayout />}>
                <Route path="/noticias" element={<News />} />
                <Route
                  path="/noticias/detalhe/:id"
                  element={<DetalheNoticia />}
                />
                <Route path="/noticias/rgpd" element={<Rgpd />} />
                <Route path="/noticias/sugestoes" element={<Sugestoes />} />
                <Route path="/noticias/notas" element={<NotasInformativas />} />
                <Route path="/noticias/protocolos" element={<Protocolos />} />
                <Route
                  path="/noticias/protocolos/:id"
                  element={<DetalheProtocolo />}
                />
                <Route path="/noticias/ementa" element={<Menu />} />
                <Route path="/noticias/horizontes" element={<Horizontes />} />
                <Route path="/noticias/organograma" element={<Organograma />} />
                <Route
                  path="/noticias/lista-telefonica"
                  element={<PhoneBook />}
                />
                <Route
                  path="/noticias/eventos/:id"
                  element={<DetalheEvento />}
                />
                <Route path="/noticias/twogo" element={<PontosEncontro />} />
              </Route>
              <Route path="/" element={<Navigate to="/noticias" />} />
            </>
          ) : null}

          {/* No Mandatory Login */}
          {!user.id_user ? (
            <>
              <Route path="/login" element={<Auth />} />
              <Route path="/signup" element={<Auth />} />
              <Route path="/recover" element={<Auth />} />

              <Route path="/kit/transfer" element={<SharedContent />} />

              {window.innerWidth <= 992 ? (
                <Route path="/landing" element={<LandingPage />} />
              ) : null}

              <Route
                path="*"
                element={
                  window.innerWidth <= 992 ? (
                    <Navigate to={`/landing?path=${location}`} />
                  ) : (
                    <Navigate to={`/login?path=${location}`} />
                  )
                }
              />
            </>
          ) : null}

          {/* Mandatory Login */}
          {user.id_user ? (
            <>
              {searchParams.get("path") ? (
                <Route
                  path="*"
                  element={<Navigate to={searchParams.get("path")!} />}
                />
              ) : (
                <Route element={<MasterLayout />}>
                  <Route path="*" element={<PrivateRoutes />} />
                </Route>
              )}
            </>
          ) : null}

          {/* Self-Evaluation */}
          {selfEvaluation.id_colaborador ? (
            <Route path="/avaliacao" element={<AutoAvaliacaoLayout />}>
              <Route path="/avaliacao" element={<AutoAvaliacaoInicio />} />
              <Route
                path="/avaliacao/:id"
                element={<QuestionarioAutoAvaliacao />}
              />
            </Route>
          ) : (
            <>
              <Route path="/avaliacao" element={<AutoAvaliacao />} />
              <Route path="/avaliacao/*" element={<AutoAvaliacao />} />
            </>
          )}
          {/* End Self-Evaluation */}

          {/* Forms */}
          <Route path="/drh/forms/answer/:hash" element={<Questionario />} />
          <Route
            path="/trainings/forms/:anonymous/:type/:token"
            element={<FormacoesQuestionario />}
          />
          {/* End Forms */}

          {/* Registo Reuniões */}
          <Route
            path="/reunioes/presenca/:hash"
            element={<RegistoPresenca />}
          />
          {/* End Registo Reuniões */}

          {/* Feedback */}
          <Route path="/feedback/:hash" element={<Feedback />} />
          <Route path="/feedback/" element={<Feedback />} />
          {/* End Feedback */}

          {/* Dashboards */}
          <Route
            path="/gestao-energia/dashboard/:id"
            element={<DashboardsPublic />}
          />
          {/* End Dashboards */}

          {/* Confirmação receção contabilidade */}
          <Route
            path="/contabilidade/confirmation-receipt"
            element={<ConfirmacaoRececao />}
          />
          {/* End Confirmação receção contabilidade */}
        </>
      ) : null}
      <Route path="*" element={<Loading />} />
    </Routes>
  );
};

export default PublicRoutes;
